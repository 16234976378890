// 
// 
// user.scss
//
// Place your own theme CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.
.input-email,
.input-email-hero{
    background: $white;
    border: 1px solid $white;
    padding: 1em;
    border-radius: 1em;
    outline: none;
    color: $dark-gray;
    transition: all .3s;
    &:focus{
        box-shadow: 0 0 8px 0px #929292;
    }
}
.input-email-hero{
    background: $white;
    border: 1px solid $primary;
    padding: 1em;
    border-radius: 1em;
    outline: none;
    color: $dark-gray;
    transition: all .3s;
    &:focus{
        box-shadow: 0 0 4px 0px $primary;
    }
}
.flex-1{
    flex: 1;
}
.linear-bg,
.linear-bg2,
.linear-bg3{
    background-color: $secondary;
    
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.mw-500{
    max-width: 500px;
}
.navbar{
    background-color: $light-gray;
}
.w-fit{
    width: fit-content;
}
.contact-us{
    color: inherit;
    text-decoration: underline;
}